import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Reveal from "react-reveal/Reveal";
import Fade from "react-reveal/Fade";
import {
  Container1440,
} from "../common/global.styled";
import HighlightGGrid from "./HighlightGGrid";

const Highlights = () => {
  return (
    <>
      <div className="w-full bg-blues-dark-blue pt-[47px]">
        <Container1440>
          <div className="w-full pl-[41px] pr-8">
            <div>
              <Fade bottom>
                <p className="typo-body md:text-right text-neutral-light-grey max-w-[432px]">
                  Plaza 273 is a Class A fifteen-story office building located in
                  San Juan’s Financial District. Its elegant and classic
                  construction boasts 221,726 square feet of rentable workspace
                  along with 880 parking spots.
                </p>
                <br />
                <p className="typo-body  md:text-right text-neutral-light-grey max-w-[432px]">
                The office building’s exterior structure is finished with elegant travertine marble. Plaza 273 tenants benefit from the Puerto Rico Act 60-2019 tax incentives.
                </p>
              </Fade>
            </div>
            <Fade bottom>
              <p className="typo-h5 text-neutral-light-grey mt-16">
                273 PONCE DE LEÓN AVE.
                <br />
                HATO REY, PUERTO RICO
              </p>
            </Fade>
            <div className="mt-4 relative h-[182px] sm:h-[250px]  w-full">
              <div className="grid absolute  h-[227px] sm:h-[310px] w-full">
                <StaticImage
                  style={{
                    gridArea: "1/1",
                    width: "100%",
                    height: "100%",
                  }}
                  layout="constrained"
                  alt="Plaza 273"
                  src={"../../images/front-cars-mobile.jpg"}
                  height={500}
                  formats={["auto", "webp", "avif"]}
                  imgStyle={{ objectPosition: "bottom" }}
                />
                <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
                  <div className="over-image"></div>
                </Reveal>
              </div>
            </div>
          </div>
        </Container1440>
      </div>
      <div >
        <Container1440>
          <div className="flex w-full flex-col  pt-20 sm:pt-[90px] px-6 pb-[42px]">
            <div className="">
              <Fade bottom>
                <p className="typo-h1 text-blues-light-blue">HIGHLIGHTS</p>
              </Fade>
            </div>
            <div className="pt-10">
              <HighlightGGrid />
            </div>
          </div>
        </Container1440>
      </div>
    </>
  );
};

export default Highlights;
