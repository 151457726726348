import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Reveal from "react-reveal/Reveal";
import Fade from "react-reveal/Fade";
import { Container1440 } from "../common/global.styled";
import HighlightGGrid from "./HighlightGGrid";

const HighlightsDesktop = () => {
  return (
    <>
      <div className="w-full bg-blues-dark-blue">
        <Container1440 className="px-4 md:px-0 md:pt-20">
          <div className="flex w-full flex-col md:flex-row">
            <div className="w-[64%] pr-8 h-full">
              <div className="flex content-end flex-col items-end">
                <Fade bottom>
                  <p className="typo-body md:text-right text-neutral-light-grey max-w-[432px]">
                    Plaza 273 is a Class A fifteen-story office building located
                    in San Juan’s Financial District. Its elegant and classic
                    construction boasts 221,726 square feet of rentable workspace
                    along with 880 parking spots.
                  </p>
                  <br />
                  <p className="typo-body  md:text-right text-neutral-light-grey max-w-[432px]">
                  The office building’s exterior structure is finished with elegant travertine marble. Plaza 273 tenants benefit from the Puerto Rico Act 60-2019 tax incentives.
                  </p>
                </Fade>
              </div>
              <div className="relative h-[404px]">
                <div className="grid absolute pt-[60px] h-[560px] w-full">
                  {/* You can use a GatsbyImage component if the image is dynamic */}
                  <StaticImage
                    style={{
                      gridArea: "1/1",
                    }}
                    layout="constrained"
                    alt="Plaza 273"
                    src={"../../images/front-cars.jpg"}
                    height={500}
                    formats={["auto", "webp", "avif"]}
                    imgStyle={{ objectPosition: "right" }}
                  />
                  <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
                    <div className="over-image"></div>
                  </Reveal>
                </div>
              </div>
            </div>
            <div className="w-[36%] md:pr-[50px]  lg:pr-[60px] xl:pr-[100px] flex items-center pl-8">
              <Fade bottom>
                <p className="typo-h5 text-neutral-light-grey">
                  273 PONCE DE LEÓN AVE.
                  <br />
                  HATO REY, PUERTO RICO
                </p>
              </Fade>
            </div>
          </div>
        </Container1440>
      </div>
      <div className="border-b-[1px] border-b-background pb-[63px]">
        <Container1440>
          <div className="flex w-full flex-col md:flex-row  pt-20">
            <div className="w-[64%] pr-[132px] pt-[150px] h-full pl-4 md:pl-[50px]  lg:pl-[60px] xl:pl-[100px]">
              <HighlightGGrid />
            </div>
            <div className="w-[36%] pl-8 pr-4 md:pr-[50px]  lg:pr-[60px] xl:pr-[100px]">
              <p className="typo-h1 text-blues-light-blue break-words">HIGHLIGHTS</p>
            </div>
          </div>
        </Container1440>
      </div>
    </>
  );
};

export default HighlightsDesktop;
