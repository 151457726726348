import React from "react";
import HighlighitItem from "./HighlighitItem";
import HomelandIcon from "../common/icons/HomelandIcon";
import CertifiedIcon from "../common/icons/CertifiedIcon";
import ParkingIcon from "../common/icons/ParkingIcon";
import PlannerIcon from "../common/icons/PlannerIcon";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const data = [
  {
    header: "homeland security approved",
    body: "Our building was inspected and approved for a Homeland Security Branch office. ",
  },
  {
    header: "certified by epa as energy star",
    body: "For the last 5 years, our building has met EPA’s strict energy performance guidelines, saving you money while also saving energy.",
  },
  {
    header: "Parking Ratio 4 for 1000 RSF",
    body: "No matter how many employees or visitors you plan to have coming into your office, our building is prepared to offer them safe and comfortable parking options.",
  },
  {
    header: "Space Planner and Engineers",
    body: "Space planners and engineers are available to help create the design and layout that is best suited to your business needs.",
  },
];

const HighlightGGrid = () => {
  const breakpoints = useBreakpoint();
  const iconDimension = {
    width: breakpoints.sm ? "41" : "65",
    height: breakpoints.sm ? "34" : "54",
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[100px] gap-y-12 md:gap-y-20">
      <HighlighitItem {...data[0]}>
        <HomelandIcon {...iconDimension} />
      </HighlighitItem>
      <HighlighitItem {...data[1]}>
        <CertifiedIcon {...iconDimension} />
      </HighlighitItem>
      <HighlighitItem {...data[2]}>
        <ParkingIcon {...iconDimension} />
      </HighlighitItem>
      <HighlighitItem {...data[3]}>
        <PlannerIcon {...iconDimension} />
      </HighlighitItem>
    </div>
  );
};

export default HighlightGGrid;
