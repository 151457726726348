import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Reveal from "react-reveal/Reveal";
import AmenityItem from "./AmenityItem";

const data = [
  {
    id: 0,
    header: "2,000",
    subheader: "Emergency Power Plant",
    unit: "kv",
    body: `The building is equipped with a state-of-the-art 2,000 KV backup emergency power plant that provides full-load electrical power to the building if the power grid fails with a secondary generator that provides backup  to the Common Areas and elevators.`,
  },
  {
    id: 1,
    header: "45,000",
    subheader: "Gallon Reserve",
    unit: "",
    body: `The building has a 45,000 gallon concrete reservoir capable of supplying the building with water seven days a week, 365 days a year.`,
  },
  {
    id: 2,
    header: "24/7",
    subheader: "Access/Security",
    unit: "",
    body: `Access and security are provided 24 hours a day, seven days a week. Security cameras are also strategically located throughout the building and parking facilities.`,
  },
];
const Amenities = () => {
  return (
    <div>
      <div className="w-full">
          <div className="grid grid-cols-1">
            <div className="gradient-white-blue px-4">
              <div className="grid relative h-[343px] ">
                <StaticImage
                  style={{
                    gridArea: "1/1",
                  }}
                  layout="constrained"
                  alt="Renovations"
                  src={"../../images/amenity.jpg"}
                  formats={["auto", "webp", "avif"]}
                  imgClassName=""
                />
                <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
                  <div className="over-image"></div>
                </Reveal>
              </div>
            </div>
            <div className="flex justify-center flex-col px-4 bg-blues-dark-blue w-full pb-12">
              <p className="typo-h1 text-blues-light-blue mt-[60px]">
                AMENITIES
              </p>
              <div className="pt-12 grid grid-cols-1 gap-y-6 md:gap-y-12">
                {data.map(({ id, header, subheader, unit, body }) => (
                  <AmenityItem
                    key={id}
                    header={header}
                    body={body}
                    subheader={subheader}
                    unit={unit}
                  />
                ))}
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Amenities;
