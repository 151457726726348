import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Reveal from "react-reveal/Reveal";
import Fade from "react-reveal/Fade";
import { Container1440Responsive } from "../common/global.styled";
import RenovationItem from "./RenovationItem";

const data = [
  {
    id: 0,
    header: "FIBER OPTICS",
    body: `Plaza 273 has Claro Puerto Rico and  Liberty Business (Prior Centennial / AT&T) fiber service. We also have Gigabit 1 from Aeronet that has accessibility to receive speeds of up to a Gigabit internet speed through Antenna.`,
  },
  {
    id: 1,
    header: "AIR CONDITIONER",
    body: `Our premises are equipped with an air conditioning system made up of two new Trane 300 ton centrifugal chillers. In addition, the building also has a standard Trane air-cooled 125 ton chiller that serves the AC loop system. In case of an emergency, the building’s auxiliary air conditioning is available for the use of tenant’s equipment (i.e. computer room, conference room) for a period of 24/7.`,
  },
  {
    id: 2,
    header: "SECURITY",
    body: `Secured access is provided 24 hours a day, seven days a week. The building is also equipped with security cameras that are strategically located throughout the building and parking facilities.`,
  },
  {
    id: 3,
    header: "JANITORIAL SERVICES",
    body: `The building’s administration provides janitorial services five nights per week, starting at 6pm, with the exception of national holidays.`,
  },
  {
    id: 4,
    header: "ELEVATOR CODE",
    body: `The four-passenger elevator can be activated with a code in order to allow tenant’s access to the premises after normal business hours.`,
  },
];
const Renovations = () => {
  return (
    <Container1440Responsive className="pt-10 md:pt-20 pb-12 md:pb-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[60px] gap-y-10">
        <div className="flex justify-center flex-col order-last md:order-first">
          <Fade bottom delay={500}>
            <p className="typo-h1 text-blues-light-blue">RENOVATIONS</p>
          </Fade>
          <div className="pt-12 grid grid-cols-1 gap-y-6">
            {data.map(({ id, header, body }) => (
              <RenovationItem key={id} header={header} body={body} />
            ))}
          </div>
        </div>
        <div>
          <div className="grid relative -mx-4 md:mx-0 h-[360px] md:h-full">
            <StaticImage
              style={{
                gridArea: "1/1",
              }}
              layout="constrained"
              alt="Renovations"
              src={"../../images/renovation.jpg"}
              formats={["auto", "webp", "avif"]}
              imgStyle={{ objectPosition: "right" }}
            />
            <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
              <div className="over-image"></div>
            </Reveal>
          </div>
        </div>
      </div>
    </Container1440Responsive>
  );
};

export default Renovations;
