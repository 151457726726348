import React from "react";
import Fade from "react-reveal/Fade";

const AmenityItem = ({ header, subheader, unit, body }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-[37%]">
        <div className="flex flex-row items-baseline">
          <Fade bottom delay={500}>
            <p className="typo-h2 text-neutral-white uppercase">
              {header}
            </p>
          </Fade>

          {unit && (
            <Fade bottom delay={200}>
              <p className="font-questrial text-[16px] text-neutral-white uppercase">
                {unit}
              </p>
            </Fade>
          )}
        </div>

        {subheader && (
          <Fade bottom>
          <p className="typo-h4 uppercase text-neutral-light-grey mt-2">
            {subheader}
          </p>
          </Fade>
        )}
      </div>
      <div className="w-[11%]"></div>
      <Fade bottom>
        <p className="w-full typo-body text-neutral-light-grey mt-2 md:mt-0 md:w-[52%]">
          {body}
        </p>
      </Fade>
    </div>
  );
};

export default AmenityItem;
