import React from "react";
import { Container1440 } from "../common/global.styled";
import logo from "../../images/header-logo.png";
import { StaticImage } from "gatsby-plugin-image";
import Reveal from "react-reveal/Reveal";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import MixBigLogo from "../common/logo/MixBigLogo";

const Header = () => {
  const breakpoints = useBreakpoint();
  return (
    <div className="w-full bg-blues-dark-blue">
      <Container1440 className="pt-10 grid grid-cols-1 md:grid-cols-2 gap-x-14 xl:gap-x-20 gap-y-[47px]  md:px-[50px]  lg:px-[60px] xl:px-[100px]">
        <div className="px-4 md:px-0">
          <Link to="/" className="hidden md:flex">
            {" "}
            <MixBigLogo width="150" height="23"/>
          </Link>
          <Fade bottom>
            <p className="typo-h0 text-neutral-white pt-[124px] md:pt-[150px] break-words">
              YOUR DREAM WORKSPACE IN PUERTO RICO
            </p>
          </Fade>
        </div>
        <div>
          <div className="grid relative md:h-full">
            {breakpoints.sm ? (
              <StaticImage
                style={{
                  gridArea: "1/1",
                  height: "100%",
                }}
                layout="fullWidth"
                alt="Plaza 273"
                src={"../../images/header-img.png"}
                formats={["auto", "webp", "avif"]}
                imgClassName="object-top md:object-right"
              />
            ) : (
              <>
                <StaticImage
                  style={{
                    gridArea: "1/1",
                    height: "100%",
                  }}
                  layout="fullWidth"
                  alt="Plaza 273"
                  src={"../../images/header-img-desktop.png"}
                  formats={["auto", "webp", "avif"]}
                  imgClassName="object-top md:object-right"
                />
                <div
                  style={{
                    gridArea: "1/1",
                    height: "100%",
                  }}
                  className="relative flex flex-row"
                >
                  <div className="w-[20%] h-full border-r-[20px] border-blues-dark-blue"></div>
                  <div className="w-[80%]  flex flex-col  h-full">
                    <div className="w-full h-full flex flex-row border-b-[40px] border-l-[20px] border-blues-dark-blue">
                      <div className="w-[40%]  h-full border-r-[20px] border-blues-dark-blue"></div>
                      <div className="w-[60%]  h-full border-l-[20px] border-blues-dark-blue"></div>
                    </div>
                    <div className="w-full  h-[20%]  border-l-[20px] border-blues-dark-blue"></div>
                  </div>
                </div>
              </>
            )}
            <Reveal effect="fadeOutDown" duration={1900} delay={300}>
              <div className="over-image"></div>
            </Reveal>
          </div>
        </div>
      </Container1440>
    </div>
  );
};

export default Header;
