import * as React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Header from "../components/home/Header";
import Highlights from "../components/home/Highlights";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import HighlightsDesktop from "../components/home/HighlightsDesktop";
import Renovations from "../components/home/Renovations";
import Amenities from "../components/home/Amenities";
import Business from "../components/home/Business";
import AmenitiesDesktop from "../components/home/AmenitiesDesktop";
import ScrollIndicator from "../components/common/ScrollIndicator";

// markup
const IndexPage = () => {
  const breakpoints = useBreakpoint();
  return (
    <Layout>
      <Seo title="Plaza 273" />
      <Header />
      <ScrollIndicator
        left="64%"
        className="hidden md:block"
        targetStyles={{
          maxWidth: "1440px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {breakpoints.sm ? <Highlights /> : <HighlightsDesktop />}
      </ScrollIndicator>
      <Renovations />
      {breakpoints.sm ? <Amenities /> : <AmenitiesDesktop />}
      <ScrollIndicator
        left="60%"
        className="hidden md:block"
        targetStyles={{
          maxWidth: "1440px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Business />
      </ScrollIndicator>
    </Layout>
  );
};

export default IndexPage;
