import React from "react";
import Fade from "react-reveal/Fade";

const RenovationItem = ({ header, body }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <Fade bottom delay={500}>
        <p className="w-full typo-h4 text-neutral-black md:text-right uppercase md:w-[37%] md:mr-[11%]">
          {header}
        </p>
      </Fade>
      <Fade bottom>
        <p className="w-full typo-body text-neutral-grey mt-2 md:mt-0 md:w-[52%]">{body}</p>
      </Fade>
    </div>
  );
};

export default RenovationItem;
