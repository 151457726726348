import React from "react";
import Fade from "react-reveal/Fade";
import { Container1440, Container1440Responsive } from "../common/global.styled";

const Business = () => {
  return (
    <Container1440 className=" md:pt-20 border-b-background border-b-[1px]">
      <div className="flex w-full flex-col md:flex-row">
        <div className="md:w-[60%] pt-[61px] md:pr-8  px-4 md:pl-[50px]  lg:pl-[60px] xl:pl-[100px]">
          <div className="w-full md:w-[388px]">
            <Fade bottom delay={500}>
              <p className="typo-h3 text-blues-light-blue">
                DO BUSINESS IN COMFORT & STYLE
              </p>
            </Fade>
            <Fade bottom>
              <p className="typo-body text-neutral-grey mt-6 md:mt-2">
                Our building takes every possible step to ensure that your
                workplace needs are taken care of, no matter what. Offering you
                safety and first-class amenities is our top priority.
              </p>
            </Fade>
          </div>
        </div>
        <div className="md:w-[40%] md:pl-8  px-4 md:pr-[50px]  lg:pr-[60px] xl:pr-[100px]">
          <Fade bottom delay={500}>
            <p className="w-full md:w-[340px] typo-h3 text-blues-light-blue pt-[60px] md:pt-[293px] max-w-full">
              ENJOY EVERYTHING THE GOLDEN MILE HAS TO OFFER
            </p>
          </Fade>
          <Fade bottom>
            <p className="typo-body text-neutral-grey mt-6 md:mt-2 pb-10 md:pb-[42px]">
              Our building takes every possible step to ensure that your
              workplace needs are taken care of, no matter what. Offering you
              safety and first-class amenities is our top priority.
            </p>
          </Fade>
        </div>
      </div>
    </Container1440>
  );
};

export default Business;
